export const durations = [
    {'label': 'Monthly', 'value': 1},
    {'label': 'Quarterly', 'value': 3},
    {'label': 'Half Yearly', 'value': 6},
    {'label': 'Yearly', 'value': 12},
];

export function duration_value(days) {
    return durations.filter(x => x.value === days)[0].label;
}
